@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html,
  body {
    font-family: 'Roboto', sans-serif;
  }
}
.gradientBg {
  background: linear-gradient(89.56deg, #1ad2fc 3.66%, #0c20f5 99.58%);
  /* bg-gradient-to-r from-blue-400 to-blue-900 for tailwind */
}
.container {
  max-width: 1300px;
  margin: 0 auto;
}
.transparentWhite {
  color: transparent;

  -webkit-text-stroke: 1px white;
}
.transparentBlack {
  color: transparent;

  -webkit-text-stroke: 1px black;
}
.textLinear {
  background: linear-gradient(89.56deg, #1ad2fc 3.66%, #0c20f5 99.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.custom-textfield {
  /* Add any additional styles for the TextField here */
}

.bg-tra {
  background: transparent;
}
